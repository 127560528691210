import styled, { css } from 'styled-components';

const LARGE_CHECKBOX_SIZE = '30px';

const checkboxDimension = isLarge => (isLarge ? LARGE_CHECKBOX_SIZE : '1.125em');

export const CheckboxWrapperStyled = styled.div`
  padding-left: ${({ isLarge }) => (isLarge ? '32px' : '20px')};
  position: relative;
  display: flex;
  align-items: center;
  min-height: ${({ isLarge }) => (isLarge ? LARGE_CHECKBOX_SIZE : 'auto')};
  ${props => props.checkboxStyles}
`;

export const CheckboxLabel = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  padding: 0 8px;
  word-break: break-word;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  &::before,
  &::after {
    border: 1px solid;
    content: ' ';
    position: absolute;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      transform 0.2s ease-in-out;
  }
  &::before {
    border-color: ${props => props.theme.colors.lightGrey};
    border-radius: 2px;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.lightGrey};
    height: ${({ isLarge }) => checkboxDimension(isLarge)};
    left: 0.125em;
    top: 0;
    width: ${({ isLarge }) => checkboxDimension(isLarge)};
  }
  &::after {
    border: 0;
    border-bottom: 3px solid ${props => props.theme.colors.primaryBlue};
    border-right: 3px solid ${props => props.theme.colors.primaryBlue};
    height: ${({ isLarge }) => (isLarge ? '1.0em' : '0.825em')};
    left: ${({ isLarge }) => (isLarge ? '0.855em' : '0.455em')};
    top: ${({ isLarge }) => (isLarge ? '4px' : '0')};
    transform-origin: center center;
    transform: rotate(45deg) scale(0);
    width: 0.5em;
  }
  &[disabled]::before {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const CheckboxInputStyles = css`
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0.00001;
  position: relative;
  z-index: 2;
  position: absolute;
  pointer-events: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:checked ~ ${CheckboxLabel}::before {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.lightGrey};
  }
  &:checked ~ ${CheckboxLabel}::after {
    transform: rotate(45deg) scale(1);
  }
`;
