import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const FeeExplanation = styled.div`
  font-size: 0.875rem;
`;

export const FeeTableRow = styled.tr`
  border-bottom: solid 1px #d5d5d5;
  &:last-child {
    ${({ theme }) => theme.medium`
      border-bottom: none;
    `}
  }
`;

const sharedCellStyles = css`
  font-size: 0.875rem;
  padding: ${props => (props.isTitle ? 0 : '1rem 0')};
  line-height: normal;
`;

export const FeeLabel = styled.div`
  font-size: ${rem('16px')};
  font-weight: bold;
`;

export const FeeLabelCell = styled.td`
  ${sharedCellStyles}
  text-align: left;
  word-break: break-word;

  .line-item-title {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 8px;
    margin-right: 1rem;
  }

  ${props =>
    props.total
      ? `
      border: none;
    `
      : `
      font-weight: normal;
    `}
`;

export const FeeValueCell = styled.td`
  ${sharedCellStyles}
  text-align: right;
  vertical-align: top;

  ${props =>
    props.total
      ? `
      border: none;
    `
      : ''}
`;

export const TotalRow = styled.tr`
  border-top: solid 1px #d5d5d5;
  font-size: 0.875rem;
  font-weight: bold;
`;

export default {
  Table,
  FeeTableRow,
  FeeExplanation,
  FeeLabelCell,
  FeeLabel,
  FeeValueCell,
  TotalRow,
};
